@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "@ng-select/ng-select/themes/default.theme.css";
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';
@import "../node_modules/leaflet/dist/leaflet.css";
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .top-20 {
    top: 5rem;
  }

  .top-n-sales-grid-template {
    grid-template-columns: 18rem auto;
  }
}

/* Provide sufficient contrast against white background */
a {
  @apply text-theme-primary-700;
  cursor: pointer;
}

a:hover {
  @apply text-theme-primary-900;
}


.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {
  box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.login-button {
  background: rgb(181, 100, 217);
  background: linear-gradient(90deg, rgba(181, 100, 217, 1) 0%, rgba(238, 96, 156, 1) 100%);
}

.icon-background {
  background: rgb(181, 100, 217);
  background: linear-gradient(0deg, rgba(181, 100, 217, 1) 0%, rgba(238, 96, 156, 1) 100%);
}

body {
  -webkit-tap-highlight-color: transparent;
}

*::-moz-selection { /* Code for Firefox */
  @apply text-theme-primary-700 bg-theme-primary-500/10;
}

*::selection {
  @apply text-theme-primary-700 bg-theme-primary-500/10;
}

*:focus {
  outline: none 0;
}

input, textarea, button, select, ng-select {
  @apply focus:ring-2
  focus:ring-theme-primary-700
  focus:outline-1 focus:outline-theme-primary-700
  focus:outline;
}

/* navbar search */
.ng-select.custom-dropdown-dark .ng-select-container {
  background-color: #1f2937;
  color: #fff;

  @apply focus:ring-2
  focus:ring-theme-primary-700
  focus:outline-1 focus:outline-theme-primary-700
  focus:outline;
}

.ng-select.custom-dropdown-dark.ng-select-focused:not(.ng-select-opened) .ng-select-container .ng-value-container .ng-input > input {
  @apply pl-5;
}

.ng-select.custom-dropdown-dark.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply border-theme-primary-900;
}

.ng-select.custom-dropdown-dark .ng-select.ng-select-focused > .ng-select-container {
  @apply border-theme-primary-700;
}

.ng-select.custom-dropdown-dark .ng-dropdown-panel {
  @apply bg-gray-200;
}

.ng-select.custom-dropdown-dark .ng-dropdown-panel-items .ng-option.ng-option-selected {
  @apply bg-gray-200;
}

.ng-select.custom-dropdown-dark .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-theme-primary-900 text-white;
}

.ng-select.custom-dropdown-dark .ng-dropdown-panel {
  @apply rounded-b-lg overflow-hidden;
}

.ng-select.custom-dropdown-dark.ng-select-focused > .ng-select-container {
  @apply shadow-none;
}

/* shift maker search */
.ng-select.dropdown-shift-maker {
  border: 0px;
  min-height: 0px;
  border-radius: 5px;
}

.ng-select.dropdown-shift-maker .ng-select-container {
  min-height: 0px;
  border-radius: 5px;
}

.ng-dropdown-panel.ng-select.dropdown-shift-maker {
  padding: 1px;
  background-color: lightgray;
}

.ng-select.dropdown-shift-maker.select-empty-employee {
  background: rgb(254 240 138);
}

.ng-select.dropdown-shift-maker.select-empty-employee .ng-select-container {
  background: rgb(254 240 138);
}

.ng-select.dropdown-shift-maker.ng-select-single.custom .ng-select-container {
  height: 26px;
}

.ng-select.dropdown-shift-maker.ng-select-single.custom .ng-select-container .ng-value-container .ng-input {
  top: 0;
}

.ng-select.dropdown-shift-maker.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply border-theme-primary-900;
}

.ng-select.dropdown-shift-maker .ng-option-marked:hover {
  @apply bg-theme-primary-900 text-white;
}

.ng-dropdown-panel.dropdown-shift-maker .ng-dropdown-panel-items .ng-option.ng-option-selected {
  @apply bg-gray-200;
}

.ng-dropdown-panel.dropdown-shift-maker .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-theme-primary-900 text-white;
}

.ng-dropdown-panel.dropdown-shift-maker {
  @apply rounded-b-lg overflow-hidden;
}

.ng-select.dropdown-shift-maker.ng-select-focused > .ng-select-container {
  @apply bg-white shadow-none;
}

/* cheqme themed dropdown */
.ng-select.cheqme-style .ng-select-container {
  @apply rounded-md border shadow-sm ring-0;
}

.ng-select.cheqme-style .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply bg-theme-primary-900 text-white;
}

.ng-select.cheqme-style .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  @apply bg-gray-200 text-black;
}

.ng-select.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  @apply border-theme-primary-700 shadow-none border;
}


